<template>
  <collapse-card
    v-if="isUploadPopUpVisible"
    v-model="collapsePopup"
    class="upload"
    :title="`Upload ${ totalUploadProgress? totalUploadProgress :''}%`"
    header-color="bg-black"
    icon-color="text-white"
    header-title-color="text-white"
    card-padding="p-0"
  >
    <template #cardContent>
      <div class="overlayShadow rounded uploadProgressModal">
        <div class="upload-pannel">
          <div class="w-full flex bg-grey-lighter text-center text-sm">
            <div class="tabHandle flex-grow py-1 cursor-pointer active border-b border-blue">
              <p>Uploads</p>
            </div>
          </div>
          <div v-if="!!uploadFileTracker">
            <span v-for="(tracker, key, i) in uploadFileTracker" :key="`uploadingFile-${key}` + i">
              <div class="border-b text-left p-2 flex items-center" v-if="!!tracker && !!tracker.file && !!tracker.progress">
                <base-svg
                  class="mr-2 filetypeIcon feather feather-image h-5 w-5 inline-block text-black"
                  src="icons/file.svg"
                  :svg-attributes="{ class: 'h-full w-full' }"
                  tag="span"
                />
                <span class="flex-grow truncate w-1/2">{{ tracker.file.filename }}</span>
                <span class="mr-2">{{ getTrackerProgress(tracker) ? Math.ceil(getTrackerProgress(tracker)) : 0 }} %</span>
                <base-svg
                  v-if="getTrackerProgress(tracker) >= 100"
                  src="icons/check.svg"
                  style="color: green;"
                  class="text-green feather feather-check h-5 w-5 inline-block"
                  :svg-attributes="{
                    class: 'h-full w-full'
                  }"
                  tag="span"
                />
                <base-svg
                  v-else
                  src="icons/loader.svg"
                  class="feather feather-loader h-5 w-5 inline-block"
                  :svg-attributes="{
                    class: 'h-full w-full'
                  }"
                  tag="span"
                />
              </div>
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #extraIcons>
      <base-svg
        class="text-white h-5 w-5 inline-block ml-2"
        src="icons/cross.svg"
        tag="span"
        @click="hideUploadPopUp()"
      />
    </template>
  </collapse-card>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import useUploadStatusPopUp from './uploadStatusPopUp';

export default defineComponent({
    components: {
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue'))
    },
    setup () {
        const { uploadFileTracker, totalUploadProgress, hideUploadPopUp, isUploadPopUpVisible, collapsePopup, getTrackerProgress } = useUploadStatusPopUp()

        return {
            uploadFileTracker,
            totalUploadProgress,
            hideUploadPopUp,
            isUploadPopUpVisible,
            collapsePopup,
            getTrackerProgress
        };
    }
});
</script>
